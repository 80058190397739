import React, { Component } from "react";
import "../css/ContactUsSection.css";

const toastTypes = {
  error: "error",
  info: "info",
  success: "success"
};

class ContactUsSection extends Component {
  state = {
    userName: "",
    emailId: "",
    message: "",
    isToastVisible: false,
    toastClass: toastTypes.info,
    toastMessage: ""
  };

  sendMail = e => {
    e.preventDefault();

    if (this.state.userName.length < 3) {
      this.showToast(toastTypes.error, "Username too small");
      return;
    }
    if (this.state.emailId.length < 5) {
      this.showToast(toastTypes.error, "EmailId too small");
      return;
    }

    if (this.state.message.length < 10 || this.state.message.length > 255) {
      this.showToast(
        toastTypes.error,
        "Message length should be between and 10 and 255 character long"
      );
      return;
    }

    this.showToast(toastTypes.info, "Sending Mail");
    var json = JSON.stringify({
      name: this.state.userName,
      email: this.state.emailId,
      message: this.state.message,
      clientApiKey: '92e43b51d5c49e1dddea7a7f63b72616',
    });
    //can send mail
    fetch("https://mail.devsv.workers.dev", {
      method: "POST",
      mode: 'cors', 
      headers: {
        "content-type": "application/json",
        'Access-Control-Allow-Origin': '*',
      },
      body: json
    })
      .then(response => {
        response.json().then(data => {
          if (data.error) this.showToast(toastTypes.error, data.message);
          else
            this.showToast(toastTypes.success, data.message);
        });
      })
      .catch(error => {
        this.showToast(
          toastTypes.error,
          "OOPS! Something went wrong. Try again!"
        );
      });
  };

  showToast = (type, message) => {
    clearTimeout(this.timer);
    this.setState({
      isToastVisible: true,
      toastClass: type,
      toastMessage: message
    });
    this.timer = setTimeout(() => {
      this.dismissToast();
    }, 2000);
  };

  dismissToast = () => {
    this.setState({
      isToastVisible: false
    });
  };

  handleUserName = e => {
    this.setState({ userName: e.target.value });
  };

  handleEmail = e => {
    this.setState({ emailId: e.target.value });
  };

  handleMessage = e => {
    this.setState({ message: e.target.value });
  };

  render() {
    return (
      <section id="contact" className="container">
        <div className="contact-section">
          <h2>CONTACT ME</h2>
          <form
            method="post"
            onSubmit={this.sendMail}
            className="form-container"
          >
            <div className="form-input-row">
              <input
                name="name"
                value={this.state.userName}
                onChange={this.handleUserName}
                placeholder="Name"
                type="text"
              />
              <input
                name="email"
                value={this.state.emailId}
                onChange={this.handleEmail}
                placeholder="Email ID"
                type="text"
              />
            </div>
            <textarea
              value={this.state.message}
              onChange={this.handleMessage}
              placeholder="Message"
              rows="4"
            />

            <input className="btn-submit" type="submit" value="SUBMIT" />
          </form>
          {this.state.isToastVisible ? (
            <div
              className={
                "toast " +
                this.state.toastClass +
                " " +
                (this.state.isToastVisible ? "toast-visible" : "toast-hidden")
              }
            >
              {this.state.toastMessage}
            </div>
          ) : null}
        </div>
      </section>
    );
  }
}

export default ContactUsSection;
