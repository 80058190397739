import React, { Component } from "react";
import "./App.css";
import NavbarSection from "./containers/NavbarSection";
import HeroSection from "./containers/HeroSection";
import ProjectSection from "./containers/ProjectSection";
import SkillSection from "./containers/SkillSection";
import ContactUsSection from "./containers/ContactUsSection";
import FooterSection from "./components/FooterSection";
import Disclaimer from "./pages/disclaimer";
import { BrowserRouter, Route, Routes } from "react-router-dom";



class App extends Component {



  render() {
    function DisclaimerPage() {
      return <Disclaimer />
    }

    function HomePage() {
      return (
        <div>
          <NavbarSection />
          <HeroSection />
          <ProjectSection />
          <SkillSection />
          <ContactUsSection />
          <FooterSection />
        </div>
      )
    }
    return (
      <div className="App">
        <BrowserRouter>
          <Routes>
            <Route path="/" exact element={<HomePage/>} />
            <Route path="/disclaimer" element={<DisclaimerPage/>} />
          </Routes>
        </BrowserRouter>

      </div>
    );
  }
}

export default App;
