import React from "react";
const FooterSection = () => {
  return (
    <footer className="container">
      <p>&copy; 2019 Chaos Programmer</p>
    </footer>
  );
};

export default FooterSection;
