import React from "react";

const SkillItem = props => {
  function getStars(rating) {
    let stars = [];
    for (var i = 0; i < 5; i++) {
      stars.push(<img src={rating > i ? "/images/star_filled.svg" : "/images/star_unfilled.svg"} />);
    }
    return stars;
  }

  return (
    <div className="skill-item-container">
      <p>{props.skillName}</p>
      {getStars(props.skillRating)}
    </div>
  );
};

export default SkillItem;
