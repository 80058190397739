import React, { Component } from "react";
import "../css/ProjectSectionCss.css";
import ProjectItem from "../components/ProjectItem";

const projectData = [
  {
    name: "Cute Boo",
    type: "Sticker Pack",
    img: "boo",
    platform: [
      {
        os: "Android",
        url:
          "https://play.google.com/store/apps/details?id=in.devsv.stickers.cute_boo"
      }
    ]
  },
  {
    name: "ADB Wireless",
    type: "Dev Tool",
    img: "adb",
    platform: [
      {
        os: "Android",
        url:
          "https://play.google.com/store/apps/details?id=in.devsv.adbwireless"
      }
    ]
  },
  // {
  //   name: "QRead",
  //   type: "Utility",
  //   img: "qread",
  //   platform: [
  //     {
  //       os: "Android",
  //       url: "https://play.google.com/store/apps/details?id=in.devsv.qread"
  //     }
  //   ]
  // },
  {
    name: "One Page Calendar",
    type: "Utility",
    img: "opc",
    platform: [
      {
        os: "Website",
        url: "https://opc.devsv.in"
      },
      {
        os: "Android",
        url:
          "https://play.google.com/store/apps/details?id=in.devsv.onepagecalendar.pro"
      }
    ]
  },
  {
    name: "Tap Tap Stop",
    type: "Game",
    img: "tap_tap_stop",
    platform: [
      {
        os: "Android",
        url:
          "https://play.google.com/store/apps/details?id=in.devsv.taptapstop"
      }
    ]
  },
  {
    name: "Thalapathy Vijay",
    type: "Entertainment",
    img: "vijay",
    platform: [
      {
        os: "Android",
        url:
          "https://play.google.com/store/apps/details?id=net.azurewebsites.subinsv.thalapathyvijay"
      },
      {
        os: "Windows",
        url:
          "https://www.microsoft.com/en-us/store/p/thalapathy-vijay/9nblggh5xkf1"
      }
    ]
  },
  {
    name: "Emmet Cheatsheet",
    type: "Dev Tool",
    img: "emmet",
    platform: [
      {
        os: "Android",
        url:
          "https://play.google.com/store/apps/details?id=subinsv.azurewebsites.net.emmetcheatsheet"
      }
    ]
  },
  {
    name: "WF Minima",
    type: "Watch Face",
    img: "wf_minima",
    platform: [
      {
        os: "Android",
        url: "https://play.google.com/store/apps/details?id=in.devsv.minima"
      }
    ]
  }
];

class ProjectSection extends Component {
  state = {};
  render() {
    return (
      <section id="project" className="project-section">
        <div className="container">
          <h2>PROJECTS</h2>
          <div className="project-list-container">
            {projectData.map(x => (
              <ProjectItem project={x} key={x.name} />
            ))}
          </div>
        </div>
      </section>
    );
  }
}

export default ProjectSection;
