import React from "react";
import "../css/ProjectItem.css";

const ProjectItem = props => {
  return (
    <div className="project-item-container">
      <img
        className="project-icon"
        src={"/images/appicons/" + props.project.img + ".png"}
        alt="logo"
      />
      <div className="project-detail-container">
        <p className="projectTitle">{props.project.name}</p>
        <p className="thin">{props.project.type}</p>
        <p className="thin">
          {props.project.platform.map(x => (
            <a rel="noreferrer" className="projectPlatform" href={x.url} target="_blank">
              {x.os} &nbsp;
            </a>
          ))}
        </p>
      </div>
    </div>
  );
};

export default ProjectItem;
