import React from "react";
import "../css/HeroSectionCss.css";

const HeroSection = () => {
  return (
    <section id="home" className="hero-section">
      <img src="/images/avatar.png" className="avatar" />
      <h1>Android Ninja</h1>
      <div>
        <p className="thin wrapped">
          Hi, I’m <span className="green-text">Subin S V</span> and I am an
          Android developer based in Chennai, India.
        </p>
      </div>
      <div className="social">
      <a href="https://blog.devsv.in" target="_blank" alt="blog">
          <img src="/images/blog.svg" />
        </a>
        <a href="https://github.com/subinsv"  target="_blank" alt="github">
          <img src="/images/github.svg" />
        </a>
        <a href="https://twitter.com/subin_sv" target="_blank" alt="twitter">
          <img src="/images/twitter.svg" />
        </a>
      </div>
    </section>
  );
};

export default HeroSection;
