import React, { Component } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import "../css/NavbarSection.css";

const navLinkItems = [
  {
    link: "#home",
    title: "Home"
  },
  {
    link: "#project",
    title: "Projects"
  },
  {
    link: "#skill",
    title: "Skills"
  },
  {
    link: "#contact",
    title: "Contact"
  }
];

class NavbarSection extends Component {
  state = {
    isMenuExpanded: false
  };

  toggleMenu = status => {
    this.setState({ isMenuExpanded: status });
  };

  navLinkClick = e => {
    this.toggleMenu(false);
  };

  showMenuIcon = () => {
    return (
      <img
        src={
          "/images/icons/" +
          (this.state.isMenuExpanded ? "close" : "hamburger") +
          ".svg"
        }
        className="menuIcon"
        onClick={() => this.toggleMenu(!this.state.isMenuExpanded)}
      />
    );
  };

  render() {
    return (
      <nav className="container">
        <div className="nav-container">
          <div>{this.showMenuIcon()}</div>
          <img src="/images/logo_full.svg" className="logo" />
          <p />
        </div>

        {this.state.isMenuExpanded ? (
          <div className="menuLinks">
            <ul>
              {navLinkItems.map(x => (
                <li>
                  <AnchorLink
                    offset="50"
                    href={x.link}
                    onClick={() => this.toggleMenu(false)}
                  >
                    {x.title}
                  </AnchorLink>
                </li>
              ))}
              <li>
                <a href="https://blog.devsv.in" target="_blank">Blog</a>
              </li>
            </ul>
          </div>
        ) : null}
      </nav>
    );
  }
}

export default NavbarSection;
