import React, { Component } from "react";
import "../css/SkillSection.css";
import SkillItem from "../components/SkillItem";

const skillList = {
  languages: [
    {
      skill: "Dart",
      rating: 5
    },

    {
      skill: "Kotlin",
      rating: 5
    },
    {
      skill: "Java",
      rating: 4
    },
    {
      skill: "JavaScript",
      rating: 4
    },
  ],
  libs: [
    {
      skill: "Flutter",
      rating: 5
    },
    {
      skill: "Android",
      rating: 4
    },
    {
      skill: "Electron",
      rating: 4
    },
    {
      skill: "ReactJs",
      rating: 4
    }
  ],
  tools: [
    {
      skill: "VS Code",
      rating: 5
    },
    {
      skill: "Android Studio",
      rating: 5
    },
    {
      skill: "Figma",
      rating: 4
    }
  ]
};

class SkillSection extends Component {
  state = {};
  render() {
    return (
      <section id="skill" className="skill-section container">
        <h2>SKILLS</h2>
        <div className="skill-list-container">
          <div>
            <h4>LANGUAGES</h4>
            {skillList.languages.map(x => (
              <SkillItem skillName={x.skill} skillRating={x.rating} />
            ))}
          </div>

          <div>
            <h4>LIBRARIES &amp; FRAMEWORKS</h4>
            {skillList.libs.map(x => (
              <SkillItem skillName={x.skill} skillRating={x.rating} />
            ))}
          </div>
          <div>
            <h4>TOOLS</h4>
            {skillList.tools.map(x => (
              <SkillItem skillName={x.skill} skillRating={x.rating} />
            ))}
          </div>
        </div>
      </section>
    );
  }
}

export default SkillSection;
